.main-content {
  margin-top: 3%;
  height: 80%;
  width: 90%;
  display: grid;
  /* background-color: #000000; */
  /* background: #88d4ab; */
  grid-template-columns: repeat(3, 2fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 35px;
}

.grid-item {
  position: relative;
  /* border: 2px solid #54DD8C; */
  /* border: 1px solid #54DD8C; */
  border: none;
  /* background-color: #00180A; */
  background: #14746f;
  box-shadow:  19px 19px 37px #11635e,
             -19px -19px 37px #178580;
  /* box-shadow: inset 19px 19px 37px #11635e,
            inset -19px -19px 37px #178580; */
  border-radius: 27px;
  position: relative;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  transition: 300ms;
  text-decoration: none;
}

.grid-item:hover {
  /* background-color: #54DD8C; */
  /* box-shadow: inset 19px 19px 37px #11635e,
            inset -19px -19px 37px #178580; */
  /* box-shadow: 19px 19px 37px #11635e,
             -19px -19px 37px #178580; */
  box-shadow: none;
  transition: 300ms;
  color: #ffffff;
}
.grid-item:hover > .text {
  color: #000000;
}

.text {
  font-family: 'MuseoModerno', cursive;
  /* color: #54DD8C; */
  color: #000000;
  /* position: absolute; */
}

/* Grid items */
#music {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
}

#resume {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
  z-index: 10;
}

#pixelart {
  grid-row: 1 / span 2;
  grid-column: 3 / span 3;
}
.pixelartimg {
  min-height: 100%;
  min-width: 100%;
  background-image: url('./pixelart3.png');
  transition: 200ms ease;
}
#pixelart img {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  transition: 200ms ease;
  max-width: 100%;
  z-index: 0;
  border-radius: 27px;
  /* background-color: #000000; */
}
#pixelart:hover > img {
  -webkit-filter: blur(4px); 
  filter: blur(4px);
  transition: 200ms ease;
  background-color: none;
}
#pixelart:hover > .pixelartimg {
  -webkit-filter: blur(4px); 
  filter: blur(4px);
  transition: 200ms ease;
  border: none;
}

#pixelart:hover {
  /* background-color: #000000; */
}

#aboutme {
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
  z-index: 10;
}

#projects {
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
}

/* Grid Icons */
.Icon {
  visibility: hidden;
  font-size: 1rem;
  color: #000000;
  transition: 200ms ease;
}

#resume:hover > .Icon {
  font-size: 4rem;
  visibility: visible;
  margin-top: 40px;
  transition: 200ms ease;
}
#aboutme:hover > .Icon {
  font-size: 4rem;
  visibility: visible;
  margin-top: 40px;
  transition: 200ms ease;
}
#projects:hover > .Icon {
  font-size: 4rem;
  visibility: visible;
  margin-top: 40px;
  transition: 200ms ease;
}
#music:hover > .Icon {
  font-size: 4rem;
  visibility: visible;
  margin-top: 40px;
  transition: 200ms ease;
}

.pixeltext {
  position: absolute;
  font-family: 'MuseoModerno', cursive;
  /* color: #54DD8C; */
  color: #000000;
  transition: 200ms ease;
}