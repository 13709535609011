h1 {
  color: #ffffff;
  text-align: center;
}

.pixel-art-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}