.aboutme-content {
  width: 100%;
  /* height: 80%; */
  position: absolute;
  top: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden; 
  
  padding: 0;
}

.aboutme {
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.aboutme-blurb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: 40px;
  padding: 30px;
  border-radius: 8px;
  border: 2px solid #000000;
}

.aboutme-general {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 30px;
  margin-top: 40px;
}

.aboutme-grid {
  width: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 40px;
}

.aboutme-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutme-grid-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-top: 20px; */
}

.aboutme-grid-title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.aboutme-section-title {
  font-family: 'MuseoModerno', cursive;
  color: #000000;
  font-size: 1.3rem;
}

.aboutme-text {
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 1.7rem;
  text-align: center;
  line-height: 40px;
  font-weight: normal;
}

.aboutme-text-p {
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 1.3rem;
  text-align: left;
  line-height: 30px;
  margin-left: 15px;
}

.aboutme-education-courses {
  width: 90%;
  margin-left: 30px;
}

.aboutme-education-courses-item {
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 1.3rem;
  line-height: 30px;
}

/* id's for the grid items */
#aboutme-education {
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
}

#aboutme-hobbies {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
}

#aboutme-other {
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
}

#aboutme-skills {
  grid-row: 1 / span 2;
  grid-column: 1 / span 1;
}