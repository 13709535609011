.music-content {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  margin-top: 13vh;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.song-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  width: 50%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90%;
  padding-top: 40px;
}

/* Songs styling */

.player {
  max-width: 830px;
  /* min-width: 700px; */
  width: 80%;
  height: 110px;
  /* background-color: #000000; */
  /* border-bottom: 2px solid #54DD8C; */
  border-radius: 15px;
  background: linear-gradient(145deg, #126864, #157c77);
  box-shadow:  7px 7px 18px #11635e,
             -7px -7px 18px #178580;

  display: flex;
}

.player .image-box {
  position: relative;
  height: 110px;
  width: 110px;
}

.player .image-box img {
  position: absolute;
  height: 110px;
  width: 110px;
  object-fit: cover;
  border-radius: 15px 0 0 15px;
}

.music-controls {
  width: calc(100% - 110px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 8px;
}

.song-title {
  font-family: 'MuseoModerno', cursive;
  /* color: #54DD8C; */
  color: #56ab91;
  font-size: 20px;
  margin-left: 15px;
}

button {
  all: unset;
}

audio {
  all: unset;
}

.play-btn {
  font-size: 1.5rem;
  /* color: #54DD8C; */
  color: #56ab91;
  transition: 200ms;
  margin-left: 15px;
}

.play-btn:hover {
  cursor: pointer;
  color: #93E1B3;
  transition: 200ms ease;
}

.volumesvg {
  margin-left: 8px;
  /* color: #54DD8C; */
  color: #56ab91;
  margin-right: 8px;
  transition: 200ms;
  font-size: 1.8rem;
}

.volumesvg:hover {
  cursor: pointer;
  color: #93E1B3;
  transition: 200ms ease;
}

.music-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.timer {
  font-size: 10px;
  font-weight: 200;
  color: rgb(196, 196, 196);
}

.slider-info {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 83%;
}

.timer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4px;
}

.timer.dtimer {
  margin-left: auto;
  margin-right: 0;
}

/* Music Context Styling */

.music-context {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 70%;
  padding-top: 20px;
}

.music-link {
  display: flex;
  width: 300px;
  height: 300px;
}

.music-link:hover > .music-logo {
  /* color: #ffffff; */
  filter: drop-shadow(-10px -10px 29px #0e5450);
  /* fill: transparent; */
  transition: 200ms ease;
}

.music-link:hover > .music-logo-shadow {
  /* color: #54DD8C; */
  /* fill: transparent; */
  filter: drop-shadow(10px 10px 29px #1a948e);
  transition: 200ms;
}

.music-logo {
  position: absolute;
  /* color: #54DD8C; */
  color: #14746f;
  filter: drop-shadow(10px 10px 29px #0e5450);
  font-size: 20vw;
  transition: 200ms ease;
  z-index: 2;
}

.music-logo-shadow {
  position: absolute;
  /* color: #ffffff; */
  color: #14746f;
  filter: drop-shadow(-10px -10px 29px #1a948e);
  font-size: 20vw;
  transition: 200ms ease;
  z-index: 1;
  /* margin-left: 6px;
  margin-top: 6px; */
}

.context-text-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 50px;
  margin-top: 25px;
}

.context-text {
  color: #54DD8C;
  font-family: 'MuseoModerno', cursive;
  font-size: 25px;
}

/* Scrollbar Styling */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #14746f;
}

::-webkit-scrollbar-thumb {
  background: #56ab91;
}

::-webkit-scrollbar-thumb:hover {
  background: #67b99a;
}