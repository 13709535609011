.main-container {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 7vh;
  width: 100vw;
  /* background-color: #000000; */
  /* border-bottom: 2px solid #54DD8C; */
  background-color: #248277;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials {
  display: flex;
  margin-left: 0;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}

.link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.link {
  text-decoration: none;
  margin-left: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ghdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
}

.lidiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
}

.ghli {
  font-size: 2rem;
  /* color: #54DD8C; */
  color: #000000;
  transition: 200ms;
}



.navlink {
  text-decoration: none;
  text-align: center;
  height: 100%;
  width: 10vw;
  font-family: 'MuseoModerno', cursive;
  /* color: #54DD8C; */
  color: #000000;
  font-size: 1.3em;
  text-decoration: none;
  /* border-left: 2px solid #54DD8C; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
}

.navlink:hover {
  /* background-color: #54DD8C; */
  background-color: #56ab91;
  color: #000000;
  transition: 200ms;
  font-size: 1.5em;
  width: 11vw;
}

.logo-text {
  margin-left: 5px;
  margin-right: auto;
  /* color: #54DD8C; */
  color: #000000;
  font-family: 'MuseoModerno', cursive;
  font-size: 1.8em;
  margin-right: .5em;
  transition: 200ms ease;
}

.logo-text:hover {
  color: #ffffff;
  transition: 200ms ease;
  cursor: pointer;
}

.contact {
  height: 100%;
  width: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  /* border-left: 2px solid #54DD8C; */
  /* border-right: 2px solid #54DD8C; */
  font-family: 'MuseoModerno', cursive;
  font-size: 1.3em;
  /* color: #54DD8C; */
  color: #000000;
}

.contact:hover {
  /* background-color: #54DD8C; */
  background-color: #56ab91;
  font-size: 1.5em;
  width: 11vw;
  color: #000000;
  cursor: pointer;
  transition: 200ms ease;
}

.contact:hover > .dropdown {
  background-color: #56ab91;
  width: 11vw;
  transition: 200ms ease;
}

/* Dropdown menu */
.dropdown {
  position: absolute;
  width: 10vw;
  /* background-color: #000000; */
  background-color: #248277;
  top: 7vh;
  animation-name: slidedown;
  animation-duration: 200ms;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* border-left: 2px solid #54DD8C;
  border-bottom: 2px solid #54DD8C; */
  border-bottom-left-radius: 10px;
  z-index: -1;
  transition: 200ms ease;
}
@keyframes slidedown{
  0% {
    top: -7vh;
  }
  100% {
    top: 7vh;
  }
}
@keyframes slideup {
  0% {
    top: 7vh;
  }
  100% {
    top: -7vh;
  }
}
.menuitem {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* color: #54DD8C; */
  color: #000000;
  padding-left: 5%;
}



.dropicon {
  margin-right: 5%;
}

@media screen and (min-width: 850px) {
  .menuitem:hover {
    /* background-color: #54DD8C; */
    background-color: #67b99a;
    color: #000000;
  }
  .ghli:hover {
    font-size: 2.3rem;
    color: #ffffff;
    transition: 200ms;
  }
}